* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* Phone number inputs */
.flag-dropdown {
  height: 3rem !important;
  width: 4.25rem !important;
  border-radius: .625rem !important ;
  background-color: #F9F9F9 !important;
}

.selected-flag{
  width: auto !important;
  background-color: #F9F9F9 !important;
}

.react-tel-input .selected-flag{
  padding: 0 !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.react-tel-input .selected-flag .flag {
  margin-left: -5px !important;
}

.inputStyle:last-child {
  margin-right: 0px !important;
}

.react-tel-input .selected-flag{
  border-radius: 1rem !important;
}

.react-tel-input .country-list .country {
  padding: 13px 9px !important;
  border-radius: .5rem !important;
}

.form-control {
  height: 3rem !important;
  width: 19.875rem !important;
  padding: 1.1875rem .5rem !important;
  border-radius: .625rem !important;
  margin-left: 5rem !important;
}

.inputStyle {
  width: 3rem !important;
  height: 3rem !important;
  margin-right: 1rem;
  background: white;
  font-size: .9375rem;
  font-weight: 600;
  border: 0.5px solid #d3d3d3;
  border-radius: .25rem;
  background-color: #f0f0f0;
}

@media (min-width: 320px) and (max-width: 425px) {
  .form-control {
    width: 12.5rem !important;
  }
}

@media only screen and (min-width: 900px) {
  :root {
    font-size: 10px !important;
  }
}

@media only screen and (min-width: 1200px) {
  :root {
    font-size: 12px !important;
  }
}

@media only screen and (min-width: 1440px) {
  :root {
    font-size: 12px !important;
  }
}